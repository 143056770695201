<template>
    <sn-dialog :dialogData.sync="dialogData" @closeDialog="closeDialog()">
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
        >
            <el-form-item label="片区名称">
                <el-input v-model="ruleForm.qymc" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="其他异常">
                <el-switch
                    v-model="jcxs.hj"
                    active-text="环境"
                    active-value="31"
                ></el-switch>
                <el-switch
                    v-model="jcxs.wj"
                    active-text="违建"
                    active-value="32"
                ></el-switch>
                <el-switch
                    v-model="jcxs.qt2"
                    active-text="其他"
                    active-value="33"
                ></el-switch>
            </el-form-item>
            <el-form-item label="采集人" prop="SJCJFZRID">
                <SnTreeCjr
                    @handleChange="getCjrItem"
                    ref="SnTreeCjr"
                ></SnTreeCjr>
                <!-- <el-select
                    v-model="ruleForm.cjrid"
                    size="mini"
                    filterable
                    placeholder="请选择采集人"
                    clearable
                >
                    <el-option
                        v-for="(item, index) in cjrList"
                        :key="index"
                        :label="item.REALNAME"
                        :value="item.ID"
                    ></el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item v-if="btnItemShow" class="footer">
                <sn-button
                    type="primary"
                    :snButton.sync="snButton"
                    @handleChange="handleChange"
                ></sn-button>
            </el-form-item>
        </el-form>
    </sn-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "daily_patrol_area_dialog",
    props: {
        dialogData: {
            default() {
                return {};
            },
        },
        zoneId: {
            type: String,
            default() {
                return "";
            },
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            jcxs: {
                hj: "31",
                wj: "32",
                qt2: "33",
            },
            jcxEnum: {
                31: "hj",
                32: "wj",
                33: "qt2",
            },
            cjrList: [],
            ruleForm: {
                qyid: "",
                qymc: "",
                cjrid: "",
                zbx: "",
            },
            rules: {
                cjrid: [
                    {
                        required: true,
                        message: "请选择采集人",
                        trigger: "blur",
                    },
                ],
            },
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
        };
    },
    created() {
        // this.getUserList();
    },
    methods: {
        ...mapActions(["getCjrList", "areaSaveform", "GetRcxcQyInfo"]),
        handleChange(type) {
            switch (type) {
                case "buttonSave": //保存
                    this.saveData();
                    break;
                case "buttonBack": //返回
                    this.dialogData.dialog = false;
                    break;
            }
        },
        // 获取详情
        async getDetail(item) {
            let re = await this.GetRcxcQyInfo({
                pzid: item.value,
            });
            let result = re && re[0];
            if (!result) {
                this.jcxs = {
                    // TODO 不优雅写法
                    hj: "31",
                    wj: "32",
                    qt2: "33",
                };
            }
            let formData = {
                cjrid: (result && result.cjrid) || "",
                qyid: (result && result.id) || "",
                qymc: (result && result.qymc) || "",
            };
            if (formData.cjrid) {
                this.$refs.SnTreeCjr.setLabelName(formData.cjrid);
            } else {
                this.$refs.SnTreeCjr.setLabelName(null);
            }
            this.ruleForm = formData;
            let zbx = result && result.zbx;
            if (zbx) {
                Object.keys(this.jcxEnum).forEach((key) => {
                    this.jcxs[this.jcxEnum[key]] = "";
                    if (zbx && zbx.indexOf(key) !== -1) {
                        this.jcxs[this.jcxEnum[key]] = key;
                    }
                });
            }
        },
        // 保存数据
        async saveData() {
            if (!this.ruleForm.cjrid) {
                this.common.showMsg("选择采集人", "warning");
                return;
            }
            let valid = this.$refs["ruleForm"].validate();
            if (valid) {
                let checkedJcx = [];
                Object.keys(this.jcxs).forEach((key) => {
                    let jcx = this.jcxs[key];
                    if (jcx) {
                        checkedJcx.push(jcx);
                    }
                });
                this.ruleForm.zbx = checkedJcx.join(",");
                let result = await this.areaSaveform(this.ruleForm);
                if (result.issuccess) {
                    this.common.showMsg("保存成功！", "success");
                    this.dialogData.dialog = false;
                    this.$emit("saveSuccess");
                } else {
                    this.common.showMsg("保存失败！", "error");
                }
            } else {
                this.common.showMsg("请检查是否填写完整！", "warning");
            }
        },
        // 获取用户列表
        async getUserList() {
            this.cjrList = await this.getCjrList({
                funid: "f1b6f5b2-de6e-11e9-81a5-000c2977b7fd",
            });
        },
        // 关闭弹框
        closeDialog() {
            this.$emit("saveSuccess");
        },
        getCjrItem(id) {
            this.ruleForm.cjrid = id;
        },
    },
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 15px;
        .short-input {
            width: 178px;
        }
        .el-switch {
            width: 160px;
        }
        .el-switch.is-checked .el-switch__core {
            border-color: #2b64d7;
            background-color: #2b64d7;
        }
        .el-radio-group {
            // float: right;
            margin-top: 0px;
            margin-left: 15px;
            .el-radio {
                margin-right: 10px;
                span.el-radio__label {
                    // TODO 不生效
                    padding-left: 2px !important;
                }
            }
        }
    }
    .has-bottom-border {
        border-bottom: 1px dashed #bacbdf;
    }
    .footer {
        border-top: 1px dashed #bacbdf;
        text-align: right;
        padding-top: 20px;
    }
}
</style>